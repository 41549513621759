import Gen from '@helper/Gen';
import BOGen from './helper/BOGen';

var bo_router = [{
        path: "dashboard",
        name: "BoDashboard",
        component: () =>
            import ("@backend/Dashboard.vue")
    },
    {
        path: "all-product/:id?",
        name: "AllProduct",
        component: () =>
            import("@backend/AllProduct.vue")
    },
    {
        path: "wine-product/:id?",
        name: "WineProduct",
        component: () =>
            import("@backend/WineProduct.vue")
    },
    {
        path: "spirit-product/:id?",
        name: "SpiritProduct",
        component: () =>
            import("@backend/SpiritProduct.vue")
    },
    {
        path: "water-product/:id?",
        name: "WaterProduct",
        component: () =>
            import("@backend/WaterProduct.vue")
    },
    {
        path: "others-product/:id?",
        name: "OtherProduct",
        component: () =>
            import("@backend/OtherProduct.vue")
    },
    {
        path: "blog-category/:id?",
        name: "AppPubPostCat",
        component: () =>
            import("@backend/AppPubPostCat.vue")
    },
    {
        path: "seo/:id?",
        name: "StaticSeo",
        component: () =>
            import("@backend/BoStaticSeo.vue")
    },
    {
        path: "web-setting/:id?",
        name: "WebSetting",
        component: () =>
            import("@backend/WebSetting.vue")
    },
    {
        path: "campaign/:id?",
        name: "AppCampaign",
        component: () =>
            import("@backend/AppCampaign.vue")
    },
    {
        path: "campaign-group/:id?",
        name: "AppCampaignGroup",
        component: () =>
            import("@backend/AppCampaignGroup.vue")
    },
    {
        path: "campaign-index/:id?",
        name: "AppCampaignInbox",
        component: () =>
            import("@backend/AppCampaignInbox.vue")
    },
    {
        path: "template/:id?",
        name: "AppTemplate",
        component: () =>
            import("@backend/AppTemplate.vue")
    },
    {
        path: "favorite/:id?",
        name: "BoFavorite",
        component: () =>
            import("@backend/BoFavorite.vue")
    },
    {
        path: "home/:id?",
        name: "BoHome",
        component: () =>
            import("@backend/BoHome.vue")
    },
    {
        path: "article/:id?",
        name: "AppPubPost",
        component: () =>
            import("@backend/AppPubPost.vue")
    },
    {
        path: "news/:id?",
        name: "AppPubNews",
        component: () =>
            import("@backend/AppPubNews.vue")
    },
    {
        path: "event/:id?",
        name: "AppPubEvent",
        component: () =>
            import("@backend/AppEvent.vue")
    },
    {
        path: "profile/:id?",
        name: "BoProfile",
        component: () =>
            import ("@backend/Profile.vue")
    },
    {
        path: "about-us/:id?",
        name: "BoAboutUs",
        component: () =>
            import ("@backend/BoAboutUs.vue")
    },
    {
        path: "clients/:id?",
        name: "BoClientsLogo",
        component: () =>
            import ("@backend/BoClientsLogo.vue")
    },
    {
        path: "wineries/:id?",
        name: "BoWineriesLogo",
        component: () =>
            import ("@backend/BoWineriesLogo.vue")
    },
    {
        path: "contact-us/:id?",
        name: "BoContact",
        component: () =>
            import ("@backend/BoContact.vue")
    },
    {
        path: "user-register/:id?",
        name: "AppUserRegister",
        component: () =>
            import ("@backend/AppUserRegister.vue")
    },
    {
        path: "service-package/:id?",
        name: "BoPackage",
        component: () =>
            import ("@backend/BoPackage.vue")
    },
    {
        path: "legal-pages/:id?",
        name: "BoLegal",
        component: () =>
            import ("@backend/BoLegal.vue")
    },
    {
        path: "faq/:id?",
        name: "BoFaq",
        component: () =>
            import ("@backend/BoFaq.vue")
    },
    {
        path: "affiliate/:id?",
        name: "BoAffiliate",
        component: () =>
            import ("@backend/BoAffiliate.vue")
    },
    {
        path: "wineries-form/:id?",
        name: "BoWineries",
        component: () =>
            import ("@backend/BoWineries.vue")
    },
    {
        path: "item-product/:id?",
        name: "BoItemProduct",
        component: () =>
            import ("@backend/MrItemProduct.vue")
    },
    {
        path: "brand/:id?",
        name: "BoBrand",
        component: () =>
            import ("@backend/MrBrand.vue")
    },
    {
        path: "grape/:id?",
        name: "BoGrape",
        component: () =>
            import("@backend/BoGrape.vue")
    },
    {
        path: "country/:id?",
        name: "BoCountry",
        component: () =>
            import ("@backend/MrCountry.vue")
    },
    {
        path: "region/:id?",
        name: "BoRegion",
        component: () =>
            import ("@backend/MrRegion.vue")
    },
    {
        path: "dry-scale/:id?",
        name: "BoDryScale",
        component: () =>
            import ("@backend/MrDryScale.vue")
    },
    {
        path: "body-size/:id?",
        name: "BoBodySize",
        component: () =>
            import ("@backend/MrBodySize.vue")
    },
    {
        path: "categories/:id?",
        name: "BoCategories",
        component: () =>
            import ("@backend/MrCategories.vue")
    },
    {
        path: "pagination/:id?",
        name: "BoPagination",
        component: () =>
            import("@backend/BoPagination.vue")
    },
    {
        path: "users/:id?",
        name: "BoUsers",
        component: () =>
            import ("@backend/Users.vue")
    },
    {
        path: "user-level/:id?",
        name: "BoUserLevel",
        component: () =>
            import ("@backend/UserLevel.vue")
    },
    // 404
    { path: '*', redirect: { name: "BoDashboard" } },
];

var menuBypassRole = ["BoProfile"]

bo_router.map((v) => {
    v.beforeEnter = (to, from, next) => {
        var user = BOGen.user()
        if (!user || !Gen.getStorage('botk')) return next({ name: "BoLogin" })
        if (menuBypassRole.indexOf(to.name) > -1) return next()
        if (user.bu_level == 0) return next()
        if (user.menuRole.indexOf(to.name) == -1) return next({ name: user.menuRole[0] })
        next()
    }
    v.meta = { bo_protected: true }
})

var data = [{
        name: "BoLogin",
        path: "login",
        component: () =>
            import ("@backend/Login.vue"),
        beforeEnter: (to, from, next) => {
            var user = BOGen.user()
            if (user && Gen.getStorage('botk')) return next({ name: "BoDashboard" })
            next()
        }
    },
    // FileManager
    {
        name: "FileManager",
        path: 'file-explore',
        component: () =>
            import ("@components/FileManagerPage.vue")
    },
    {
        path: "",
        component: () =>
            import ('@/layout/BoLayout.vue'),
        redirect: { name: 'BoDashboard' },
        children: bo_router
    },
]

export default data