import Gen from "@helper/Gen"
var fo_router = [
    {
        name: "Home",
        path: "",
        component: () => import("./frontend/Home.vue"),
    },{
        name: "About",
        path: "about-us",
        component: () => import("./frontend/About.vue"),
    },{
        name: "Client",
        path: "clients",
        component: () => import("./frontend/Client.vue"),
    },{
        name: "WineriesList",
        path: "wineries-list",
        component: () => import("./frontend/WineriesList.vue"),
    },{
        name: "Event",
        path: "event",
        component: () => import("./frontend/Event.vue"),
    },{
        name: "DetailEvent",
        path: "event/:slug",
        component: () => import("./frontend/DetailEvent.vue"),
    },{
        name: "Blog",
        path: "blog",
        component: () => import("./frontend/Blog.vue"),
    },{
        name: "DetailBlog",
        path: "blog/:slug",
        component: () => import("./frontend/DetailBlog.vue"),
    },{
        name: "News",
        path: "news",
        component: () => import("./frontend/News.vue"),
    },{
        name: "DetailNews",
        path: "news/:slug",
        component: () => import("./frontend/DetailNews.vue"),
    },{
        name: "CustomerFavourite",
        path: "customer-favourite",
        component: () => import("./frontend/CustomerFavourite.vue"),
    },{
        name: "DetailProduct",
        path: "detail-product/:slug",
        component: () => import("./frontend/DetailProduct.vue"),
    },{
        name: "Faq",
        path: "faq",
        component: () => import("./frontend/Faq.vue"),
    },{
        name: "Product",
        path: "wine",
        component: () => import("./frontend/Product.vue"),
    },{
        name: "Product2",
        path: "spirits-liquer",
        component: () => import("./frontend/Product2.vue"),
    },{
        name: "Product4",
        path: "others",
        component: () => import("./frontend/Product4.vue"),
    },{
        name: "Product3",
        path: "water",
        component: () => import("./frontend/Product3.vue"),
    },{
        name: "Contact",
        path: "contact-us",
        component: () => import("./frontend/Contact.vue"),
    },{
        name: "Package",
        path: "package",
        component: () => import("./frontend/Package.vue"),
    },{
        name: "Affiliate",
        path: "affiliate",
        component: () => import("./frontend/Affiliate.vue"),
    },{
        name: "Wineries",
        path: "wineries",
        component: () => import("./frontend/Wineries.vue"),
    },{
        name: "Toc",
        path: "term-and-condition",
        component: () => import("./frontend/Toc.vue"),
    },{
        name: "PrivacyPolicy",
        path: "privacy-policy",
        component: () => import("./frontend/PrivacyPolicy.vue"),
    },{
        name: "CookiePolicy",
        path: "cookie-policy",
        component: () => import("./frontend/CookiePolicy.vue"),
    },{
        name: "Login",
        path: "signin",
        component: () => import("./frontend/Login.vue"),
    },{
        name: "Signup",
        path: "signup",
        component: () => import("./frontend/Signup.vue"),
    },
    {
        name: "ForgotPassword",
        path: "forgot-password",
        component: () => import("./frontend/ForgotPassword.vue"),
    },
    {
        name: "FormForgotPassword",
        path: "change-password/:code",
        component: () => import("./frontend/FormForgotPassword.vue"),
    },
    {
        name: "404",
        path: "*",
        component: () => import("./frontend/Page404.vue"),
    }
]
fo_router.map((v) => {
	v.beforeEnter = (to, from, next) => {
		if (typeof $ != 'undefined') $("html,body").scrollTop(0)
		next()
	}
})
var data = [{
		name: "logout",
		path: "logout",
		beforeEnter(from, to, next) {
			Gen.delCookie("fotk")
			Gen.delStorage("fotk")
			if (typeof App != "undefined") App.user = null
			next({
				name: 'Home'
			})
		},
	},
	{
		path: "/",
		component: () => import('@/layout/FoLayout.vue'),
		children: fo_router
	},
]
export default data