import Gen from "@helper/Gen"

// import Gen from "./helper/Gen.js"
/* Router using FoLayout */
var private_router = [
  {
    name: "Dashboard",
    path: "dashboard",
    component: () => import("./frontend/private/Dashboard.vue"),
  },
  {
    name: "AccountSetting",
    path: "account-setting",
    component: () => import("./frontend/private/AccountSetting.vue"),
  },
  // {
  //   name: "404",
  //   path: "*",
  //   component: () => import("./frontend/Page404.vue"),
  // },
]

private_router.map((v)=>{
  v.beforeEnter = function(to, from, next){
    if(typeof $ != 'undefined') $("html,body").scrollTop(0)
    if(!Gen.userToken()) return next({name:'Login'})
    next()
  }
})

var data = [
  {
    path:"",
    component: () => import('@/layout/FoLayout.vue'),
    redirect: {name:'pvDashboard'},
    children: private_router
  },
]

export default data